import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Navbar from '../components/Navbar';
import { RESTORE_TOKEN, useAuth } from '../context/authContext';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Products from '../pages/Products';
import NoPage from '../pages/NoPage';
import { ERROR, get_current_user, refreshToken } from './auth';
import Savs from '../pages/Savs';
import Users from '../pages/Users';
import ProductForm from '../pages/ProductForm';
import Product from '../pages/Product';
import SavForm from '../pages/SavForm';
import Sav from '../pages/Sav';
import UserForm from '../pages/UserForm';
import User from '../pages/User';

const Manager = () => {

    const { authState, authDispatcher } = useAuth();

    useEffect(() => {
        const token = refreshToken();
        if(token === null) {
            authDispatcher({type: RESTORE_TOKEN, token: null, user: null});
        } else {
            get_current_user(token).then((user) => {
                if(user === ERROR) {
                    localStorage.clear();
                    authDispatcher({type: RESTORE_TOKEN, token: null, user: null});
                } else {
                    authDispatcher({ type: RESTORE_TOKEN, token: token });
                }
                
            });
        }
    }, []);

    return (
        <Router>
            {
                authState.userToken !== null && (
                    <Navbar />
                )
            }
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/login" exact component={Login} />
                <Route path="/products" exact component={Products} />
                <Route path="/product/add" exact component={ProductForm} />
                <Route path="/product/:productId" exact component={Product} />
                <Route path="/product/edit/:productId" exact component={ProductForm} />
                <Route path="/savs" exact component={Savs} />
                <Route path="/sav/add" exact component={SavForm} />
                <Route path="/sav/edit/:savId" exact component={SavForm} />
                <Route path="/sav/:savId" exact component={Sav} />
                <Route path="/users" exact component={Users} />
                <Route path="/user/add" exact component={UserForm} />
                <Route path="/user/edit/:userId" exact component={UserForm} />
                <Route path="/user/:userId" exact component={User} />
                <Route component={NoPage} />
            </Switch>
        </Router>
    )
}

export default Manager;