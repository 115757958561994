import { ErrorMessage, Field, Form, Formik } from 'formik';
import { React, useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import Alert from '../components/Alert';
import { useAuth } from '../context/authContext';
import { add_user, ERROR, get_roles, get_user, update_user } from '../tools/auth';
import { BAD_VALUE } from '../tools/content';
import ProtectedRoute from '../tools/ProtectedRoute';

const UserForm = () => {

    let params = useParams();
    const [initalValues, setInitalValues] = useState({
        name: '',
        surname: '',
        email: '',
        password: '',
        confirmation: '',
        role: ''
    });
    const [userValidation, setUserValidation] = useState(Yup.object().shape({
        name: Yup.string().required('Le nom est obligatoire'),
        surname: Yup.string().required('Le prénom est obligatoire'),
        email: Yup.string().email('Adresse e-mail invalie').required('L\'adresse e-mail est obligatoire'),
        password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'Le mot de passe doit contenir au minimum 8 caractères avec 1 majuscule, 1 minuscule et 1 chiffre.'),
        confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Les mot de passes ne sont pas égaux'),
        role: Yup.string()
    }));
    const [isLoading, setIsloading] = useState(true);
    const [error, setError] = useState(null);
    const [errorBanner, setErrorBanner] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [canEditRole, setCanEditRole] = useState(false);
    const [roles, setRoles] = useState([]);
    const [oldEmail, setOldEmail] = useState('');
    const [success, setSuccess] = useState(null);
    const { authState } = useAuth();

    const submit = (values) => {
        console.log(values);
        if (params.userId) {
            //Update
            update_user(authState.userToken, params.userId, values.name, values.surname, values.email, oldEmail, values.password, values.role).then((response) => {
                switch (response) {
                    case BAD_VALUE: {
                        setErrorBanner('Veuillez vérifier vos valeurs');
                        break;
                    }
                    case ERROR: {
                        setErrorBanner('Une erreur est survenue');
                        break;
                    }
                    default: {
                        setSuccess(response.id);
                    }
                }
            });
        } else {
            //Create
            add_user(authState.userToken, values.name, values.surname, values.email, values.password, values.role).then((response) => {
                switch (response) {
                    case BAD_VALUE: {
                        setErrorBanner('Veuillez vérifier vos valeurs');
                        break;
                    }
                    case ERROR: {
                        setErrorBanner('Une erreur est survenue');
                        break;
                    }
                    default: {
                        setSuccess(response.id);
                    }
                }
            });
        }
    }

    useEffect(() => {
        if (authState.userToken && authState.user) {
            if (params.userId) {
                //Edit user
                setUserValidation(Yup.object().shape({
                    name: Yup.string().required('Le nom est obligatoire'),
                    surname: Yup.string().required('Le prénom est obligatoire'),
                    email: Yup.string().email('Adresse e-mail invalie').required('L\'adresse e-mail est obligatoire'),
                    password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'Le mot de passe doit contenir au minimum 8 caractères avec 1 majuscule, 1 minuscule et 1 chiffre.'),
                    confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Les mot de passes ne sont pas égaux')
                }));
                get_user(authState.userToken, params.userId).then((response) => {
                    setOldEmail(response.email);
                    if (authState.user.id !== params.userId) {
                        //Check is if an admin
                        if (authState.user.role.can_edit_user === 1) {
                            setCanEditRole(true);
                            get_roles(authState.userToken).then((responseToken) => {
                                let roleID = '';
                                //Get current role ID
                                responseToken.forEach(element => {
                                    if (element.name === response.role.name) {
                                        roleID = element.id
                                    }
                                });
                                setInitalValues({ 
                                    name: response.name,
                                    surname: response.surname,
                                    email: response.email,
                                    password: '',
                                    confirmation: '',
                                    role: roleID });
                                setRoles(responseToken);
                                setIsloading(false);
                            });
                        } else {
                            setRedirect(true);
                            setIsloading(false);
                        }
                    } else {
                        setCanEditRole(false);
                        setInitalValues({
                            name: response.name,
                            surname: response.surname,
                            email: response.email,
                            password: '',
                            confirmation: '',
                        });
                        setIsloading(false);
                    }
                });
            } else {
                //Create user
                if (!authState.user.role.can_edit_user) {
                    setRedirect(true);
                    setIsloading(false);
                } else {
                    setCanEditRole(true);
                    get_roles(authState.userToken).then((response) => {
                        if (response === ERROR) {
                            setError('Une erreur est survenue.');
                        } else {
                            setRoles(response);
                        }
                    });
                    setUserValidation(Yup.object().shape({
                        name: Yup.string().required('Le nom est obligatoire'),
                        surname: Yup.string().required('Le prénom est obligatoire'),
                        email: Yup.string().email('Adresse e-mail invalie').required('L\'adresse e-mail est obligatoire'),
                        password: Yup.string().matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'Le mot de passe doit contenir au minimum 8 caractères avec 1 majuscule, 1 minuscule et 1 chiffre.').required('Le mot de passe est obligatoire'),
                        confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Les mot de passes ne sont pas égaux').required('La confirmation de mot de passe est obligatoire'),
                        role: Yup.string().required('Le role est obligatoire')
                    }));
                    setIsloading(false);
                }
            }
        }
    }, [params, authState]);

    return (
        <ProtectedRoute>
            <section className="page page--user-form">
                <div className="container">
                    <div className="row">
                        {
                            isLoading ? (
                                <h2>Chargement ...</h2>
                            ) : error ? (
                                <Alert message={error} />
                            ) : redirect ? (
                                <Redirect to="/users" />
                            ) : success ? (
                                <Redirect to={`/user/${success}`} />
                            ) : (
                                <>
                                    <div className="col-xs-12 col-md-4">
                                        <h2 className="title title--large">{params.userId ? 'Modifier' : 'Ajouter'} un utilisateur</h2>
                                        <p className="title title--medium">{params.userId ? 'Modifiez' : 'Ajoutez'} un utilisateur au système</p>
                                    </div>
                                    <div className="col-xs-12 col-md-1" />
                                    <div className="col-xs-12 col-md-7">
                                        {
                                            errorBanner && (
                                                <Alert message={errorBanner} />
                                            )
                                        }
                                        <Formik
                                            initialValues={initalValues}
                                            validationSchema={userValidation}
                                            onSubmit={submit}>
                                            <Form className="form">
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-md-6">
                                                        <label htmlFor="name">Nom</label>
                                                        <Field name="name" type="text" />
                                                        <ErrorMessage name="name" component="label" className="form--error" />
                                                    </div>
                                                    <div className="col-xs-12 col-md-6">
                                                        <label htmlFor="surname">Prénom</label>
                                                        <Field name="surname" type="text" />
                                                        <ErrorMessage name="surname" component="label" className="form--error" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12">
                                                        <label htmlFor="email">Adresse e-mail</label>
                                                        <Field name="email" type="email" />
                                                        <ErrorMessage name="email" component="label" className="form--error" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-md-6">
                                                        <label htmlFor="password">Mot de passe</label>
                                                        <Field name="password" type="password" />
                                                        <ErrorMessage name="password" component="label" className="form--error" />
                                                    </div>
                                                    <div className="col-xs-12 col-md-6">
                                                        <label htmlFor="confirmation">Confirmer mot de passe</label>
                                                        <Field name="confirmation" type="password" />
                                                        <ErrorMessage name="confirmation" component="label" className="form--error" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12">
                                                        <label htmlFor="role">Role</label>
                                                        <Field name="role" as="select" disabled={canEditRole === false ? true : false}>
                                                            <option value="">Sélectionez un role</option>
                                                            {
                                                                roles.map(role => (
                                                                    <option key={role.id} value={role.id}>{role.name}</option>
                                                                ))
                                                            }
                                                        </Field>
                                                        <ErrorMessage name="role" component="label" className="form--error" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12">
                                                        <button type="submit" className="btn btn--green btn--full-width">{params.userId ? 'Modifier' : 'Ajouter'} un utilisateur</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </section>
        </ProtectedRoute>
    );
}

export default UserForm;