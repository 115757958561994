import React, { useState } from 'react';
import LogoNeos from '../assets/images/logo/logo-neos-large.png';
import Background from '../assets/images/other/background-login.png';
import { Form, Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { BAD_LOGIN, ERROR, ERROR_EMPTY, login } from '../tools/auth';
import { SIGN_IN, useAuth } from '../context/authContext';
import LoggedOutRoute from '../tools/LoggedOutRoute';
import Alert from '../components/Alert';

const Login = () => {

    const [errorMessage, setErrorMessage] = useState('');
    const { authDispatcher } = useAuth();

    const initialValues = {
        username: '',
        password: ''
    };

    const loginValidation = Yup.object().shape({
        username: Yup.string().required("Le nom d'utilisateur est requis"),
        password: Yup.string().required("Le mot de passe est requis")
    });

    return (
        <LoggedOutRoute>
            <section className="page-login">
                <div className="page-background">
                    <img src={Background} alt='' />
                </div>
                <div className="page-content">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={loginValidation}
                        onSubmit={(values) => {
                            login(values.username, values.password).then((token) => {
                                if (token === ERROR_EMPTY) {
                                    setErrorMessage('Veuillez remplir tous les champs');
                                } else if (token === BAD_LOGIN) {
                                    setErrorMessage("Le nom d'utilisateur ou le mot de passe est incorrect.");
                                } else if (token === ERROR) {
                                    setErrorMessage("Une erreur est survenue au niveau du serveur.")
                                } else {
                                    authDispatcher({ type: SIGN_IN, token: token.access_token, user: null });
                                }
                            });

                        }}>
                        <Form className="form">
                            <img src={LogoNeos} className="logo" alt='Neos Health' />
                            <h1 className="title title--medium">Se connecter</h1>
                            {
                                errorMessage && (
                                    <Alert message={ errorMessage }/>
                                )
                            }
                            <div className="form-row">
                                <div className="col-xs-12">
                                    <label htmlFor="username">Nom d'utilisateur</label>
                                    <Field type="text" name="username" />
                                    <ErrorMessage name="username" component="label" className="form--error" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-xs-12">
                                    <label htmlFor="password">Mot de passe</label>
                                    <Field type="password" name="password" />
                                    <ErrorMessage name="password" component="label" className="form--error" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col-xs-12">
                                    <button type="submit" className="btn btn--black">Se connecter</button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </section>
        </LoggedOutRoute>
    );
}

export default Login;