import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { RESTORE_TOKEN, SIGN_OUT, useAuth } from '../context/authContext';
import { ERROR, get_current_user } from './auth';

const ProtectedRoute = ({ children }) => {
    const { authState, authDispatcher } = useAuth();

    useEffect(() => {
        if(authState.userToken !== null) {
            get_current_user(authState.userToken).then((response) => {
                if(response === ERROR) {
                    authDispatcher({type: SIGN_OUT});
                } else {
                    authDispatcher({type: RESTORE_TOKEN, token: authState.userToken, user: response});
                }
            });
        }
    }, [authState.userToken]);

    return (
        <>
            {
                authState.isLoading === false && authState.userToken === null ? (
                    <Redirect to={{ pathname: "/login" }} />
                ) : (
                    <>
                        {children}
                    </>
                )
            }
        </>
    );
}

export default ProtectedRoute;