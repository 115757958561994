import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Alert from '../components/Alert';
import { useAuth } from '../context/authContext';
import { ERROR } from '../tools/auth';
import { get_single_device, NOT_FOUND } from '../tools/content';
import ProtectedRoute from '../tools/ProtectedRoute';

const Product = () => {

    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { productId } = useParams();
    const { authState } = useAuth();

    useEffect(() => {
        setIsLoading(true);
        if (authState.userToken !== null) {
            get_single_device(authState.userToken, productId).then((response) => {
                if (response === NOT_FOUND) {
                    setError('Aucun résultat');
                } else if (response === ERROR) {
                    setError('Une erreur est survenue');
                } else {
                    setProduct(response);
                }
                setIsLoading(false);
            });
        }
    }, [authState.userToken, productId]);

    return (
        <ProtectedRoute>
            <section className="page page--product">
                <div className="container">
                    <div className="row">
                        {
                            isLoading === true ? (
                                <div className="col-xs-12">
                                    <h2>Chargement ...</h2>
                                </div>
                            ) : error !== null ? (
                                <div className="col-xs-12">
                                    <Alert message={error} />
                                </div>
                            ) : (
                                <>
                                    <div className="col-xs-12 col-md-4">
                                        <h2 className="title title--large">{product.device.serial_number}</h2>
                                        <h3 className="title title--medium">{`${product.device.product.brand.name} ${product.device.product.name}`}</h3>
                                        <p className="text">Date de mise en service :<br />{new Date(product.device.boot_date).toLocaleDateString(undefined)}</p>
                                        <p className="text">Commande N° :<br />{product.device.order.ecom_number}</p>
                                        <p className="text">Status de la garantie :<br />{ product.waranty_state ? 'Valide' : 'Expirée' }</p>
                                        <Link to={`/product/edit/${product.device.id}`} className="btn btn--black">Modifier</Link>
                                    </div>
                                    <div className="col-xs-12 col-md-1" />
                                    <div className="col-xs-12 col-md-7">
                                        <h2 className="title title--large">Historique</h2>
                                        <ul className="list">
                                            {
                                                product.history.map((item, index) => (
                                                    <li key={index} className="list-item">
                                                        <Link to={item.type === 'SAV' ? `/sav/${item.id}` : '#'}>
                                                            <div className="list-item--body">
                                                                <div className="list-item--small-col">
                                                                    <p className="list-item--date">{ new Date(item.date).toLocaleDateString(undefined) }</p>
                                                                </div>
                                                                <div className="list-item--large-col">
                                                                    <p className="list-item--data">{ item.type === 'SAV' ? `SAV N°${item.number}` : item.edit_type === 'warranty' ? 'Modification de la garantie' : item.edit_type === 'serial_number' ? `Changement de numéro de série. Anciennement ${item.old_value}` : item.edit_type === 'product_id' ? 'Changement de modèle' : item.edit_type === 'boot_date' ? `Modification de la date de mise en service. Anciennement ${new Date(item.old_value).toLocaleDateString(undefined)}` : 'Modification' }</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                ))
                                            }
                                            <li className="list-item">
                                                <div className="list-item--body">
                                                    <div className="list-item--small-col">
                                                        <p className="list-item--date">{ new Date(product.device.created_at).toLocaleDateString(undefined) }</p>
                                                    </div>
                                                    <div className="list-item--large-col">
                                                        <p className="list-item--data">Enregistrement dans le système</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </section>
        </ProtectedRoute>
    );
}

export default Product;