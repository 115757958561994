import axios from "axios"
import { ERROR } from "./auth";
import { ADD_DEVICE, ADD_SAV, ARRIVED_SAV, GET_ALL_DEVICES, GET_ALL_PRODUCTS, GET_ALL_SAVS, GET_DASHBAORD, GET_DEVICE, GET_SAV, SEARCH_DEVICE, SEARCH_SAV, UPDATE_DEVICE, UPDATE_SAV, WORKING_SAV } from "./ServerEndpoint"

export const NOT_FOUND = 'NOT_FOUND';
export const MISSING_VALUES = 'MISSING_VALUES';
export const BAD_VALUE = 'BAD_VALUE';
export const ALREADY_EXISTS = 'ALREADY_EXISTS';
export const OK = 'OK';

export const get_dashboard = async (token = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.get(GET_DASHBAORD, { headers });
        return response.data;
    } catch (error) {
        return ERROR;
    }
}

export const get_devices = async (token = '', page = 1) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.get(`${GET_ALL_DEVICES}?page=${page}`, { headers });
        return response.data;
    } catch (error) {
        return ERROR;
    }
}

export const search_devices = async (token = '', query = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios(`${SEARCH_DEVICE}?query=${query}`, { headers });
        return response.data;
    } catch (error) {
        return ERROR
    }
}

export const get_single_device = async (token = '', id = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.get(`${GET_DEVICE}/${id}`, { headers });
        return response.data;
    } catch (error) {
        switch (error.response.stauts) {
            case 404:
                return NOT_FOUND;
            default:
                return ERROR;
        }
    }
}

export const add_device = async (token = '', order_number = '', serial_number = '', boot_date = '', product_id = '', warranty = 'NONE') => {
    if (token === '' || order_number === '' || serial_number === '' || boot_date === '' || product_id === '' || warranty === '') {
        return MISSING_VALUES;
    }

    //Get on JS Date format
    const boot = new Date(boot_date);
    const formatedDate = boot.getFullYear() + "-" +
        ("00" + (boot.getMonth() + 1)).slice(-2) + "-" +
        ("00" + boot.getDate()).slice(-2);


    if (warranty === 'NONE' || warranty === '6MONTH' || warranty === '12MONTH') {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }

        const formData = new FormData();
        formData.append('order_number', order_number);
        formData.append('serial_number', serial_number);
        formData.append('boot_date', formatedDate);
        formData.append('product_id', product_id);
        formData.append('warranty', warranty);

        try {
            const response = await axios.post(ADD_DEVICE, formData, { headers });
            return response.data;
        } catch (error) {
            switch (error.response.status) {
                case 422: {
                    if (error.response.data.errors.serial_number) {
                        return ALREADY_EXISTS;
                    } else {
                        return BAD_VALUE;
                    }
                }
                default: {
                    return ERROR;
                }
            }
        }
    } else {
        return BAD_VALUE;
    }
}

export const update_device = async (token = '', id = '', order_number = '', serial_number = '', old_serial = '',boot_date = '', product_id = '', warranty = 'NONE') => {
    if (token === '' || order_number === '' || serial_number === '' || boot_date === '' || product_id === '' || warranty === '' || id === '' || old_serial === '') {
        return MISSING_VALUES;
    }

    //Get on JS Date format
    const boot = new Date(boot_date);
    const formatedDate = boot.getFullYear() + "-" +
        ("00" + (boot.getMonth() + 1)).slice(-2) + "-" +
        ("00" + boot.getDate()).slice(-2);


    if (warranty === 'NONE' || warranty === '6MONTH' || warranty === '12MONTH') {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }

        const formData = new FormData();
        formData.append('order_number', order_number);
        if(old_serial !== serial_number) {
            formData.append('serial_number', serial_number);
        }
        formData.append('boot_date', formatedDate);
        formData.append('product_id', product_id);
        formData.append('warranty', warranty);

        try {
            const response = await axios.post(`${UPDATE_DEVICE}/${id}`, formData, { headers });
            return response.data;
        } catch (error) {
            switch (error.response.status) {
                case 422: {
                    if (error.response.data.errors.serial_number) {
                        return ALREADY_EXISTS;
                    } else {
                        return BAD_VALUE;
                    }
                }
                default: {
                    return ERROR;
                }
            }
        }
    } else {
        return BAD_VALUE;
    }
}

export const get_all_products = async (token = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.get(GET_ALL_PRODUCTS, { headers });
        return response.data;
    } catch (error) {
        return ERROR;
    }
}

export const get_savs = async (token = '', page = 1, filter = 'all') => {
    if(filter === 'all' || filter === 'registred' || filter === 'transit' || filter === 'waiting' || filter === 'processing' || filter === 'terminated') {
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }

        try {
            const response = await axios.get(`${GET_ALL_SAVS}?page=${page}&query=${filter}`, { headers });
            return response.data;
        } catch (error) {
            return ERROR;
        }
    } else {
        return BAD_VALUE;
    }
}

export const search_sav = async (token = '', page = 1, query = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.get(`${SEARCH_SAV}?page=${page}&query=${query}`, { headers });
        return response.data;
    } catch (error) {
        return ERROR;
    }
}

export const add_sav = async (token = '', name = '', surname = '', device_id = '', woo_number = '', client_tracking = '', notes = '' ) => {
    if(name === '' || surname === '' || device_id === '' || woo_number === '') {
        return MISSING_VALUES;
    }

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    };

    const formData = new FormData();
    formData.append('client_name',name);
    formData.append('client_surname', surname);
    formData.append('device_id', device_id);
    formData.append('woo_number', woo_number);
    if(client_tracking !== '') {
        formData.append('client_tracking', client_tracking);
    }
    if(notes !== '') {
        formData.append('notes', notes);
    }

    try {
        const result = await axios.post(ADD_SAV, formData, { headers });
        return result.data;
    } catch (error) {
        if(error.response.status === 422) {
            return BAD_VALUE;
        } else {
            return ERROR;
        }
    }
}

export const edit_sav = async (token = '', id='', name = '', surname = '', device_id = '', woo_number = '', client_tracking = '', return_tracking = '', notes = '' ) => {
    if(name === '' || surname === '' || device_id === '' || woo_number === '' || id === '') {
        return MISSING_VALUES;
    }

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    };

    const formData = new FormData();
    formData.append('client_name',name);
    formData.append('client_surname', surname);
    formData.append('device_id', device_id);
    formData.append('woo_number', woo_number);
    if(client_tracking !== '') {
        formData.append('client_tracking', client_tracking);
    }
    if(return_tracking !== '') {
        formData.append('return_tracking', return_tracking);
    }
    if(notes !== '') {
        formData.append('notes', notes);
    }

    try {
        const result = await axios.post(`${UPDATE_SAV}/${id}`, formData, { headers });
        return result.data;
    } catch (error) {
        if(error.response.status === 422) {
            return BAD_VALUE;
        } else {
            return ERROR;
        }
    }
}

export const get_sav = async (token = '', id = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    };

    try {
        const result = await axios.get(`${GET_SAV}/${id}`, { headers });
        return result.data;
    } catch (error) {
        if(error.response.status === 404) {
            return NOT_FOUND;
        } else {
            return ERROR;
        }
    }
}

export const sav_arrived = async (token = '', id = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    };

    try {
        await axios.put(`${ARRIVED_SAV}/${id}`, null,{ headers });
        return OK;
    } catch (error) {
        if(error.response.status === 422) {
            return BAD_VALUE;
        } else {
            return ERROR;
        }
    }
}

export const sav_working = async (token = '', id = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    };

    try {
        await axios.put(`${WORKING_SAV}/${id}`, null,{ headers });
        return OK;
    } catch (error) {
        if(error.response.status === 422) {
            return BAD_VALUE;
        } else {
            return ERROR;
        }
    }
}