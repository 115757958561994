import React, { useRef, useState, useEffect } from 'react';
import ProtectedRoute from '../tools/ProtectedRoute';
import Cards from '../components/Cards';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { get_devices, search_devices } from '../tools/content';
import { ERROR } from '../tools/auth';

const Products = () => {

    const searchQuery = useRef();
    const [deviceList, setDeviceList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const { authState } = useAuth();

    const pushToDeviceList = (data = [], clear = false) => {
        const newData = [];

        data.forEach(element => {
            newData.push({
                title: element.serial_number,
                subTitle: `${element.product.brand.name} ${element.product.name}`,
                color: 'black',
                link: `product/${element.id}`
            });
        });

        if (clear) {
            setDeviceList(newData);
        } else {
            setDeviceList([
                ...deviceList,
                ...newData
            ]);
        }
    }

    const getData = (type = 'all', query = '', clear = false) => {
        if (type === 'all') {
            get_devices(authState.userToken, currentPage).then((response) => {
                setTotalItems(response.total);
                setCurrentPage(currentPage + 1);
                pushToDeviceList(response.data, clear);
                setIsLoading(false);
            });
        } else if (query !== '') {
            search_devices(authState.userToken, query).then((response) => {
                if (response !== ERROR) {
                    setCurrentPage(1);
                    pushToDeviceList(response.data, true);
                    setIsLoading(false);
                }
            });
        }
    }

    const fetchData = () => {
        getData();
    }

    useEffect(() => {
        if (authState.userToken !== null) {
            getData();
        }
    }, [authState.userToken]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchQuery.current.value === '') {
            setCurrentPage(1);
            setDeviceList([]);
            getData('all', '', true);
        } else {
            getData('search', searchQuery.current.value);
        }
    }

    return (
        <ProtectedRoute>
            <section className="page page--products">
                <div className="container">
                    {
                        isLoading ? (
                            <h2>Chargement ...</h2>
                        ) : (
                            <>
                                <div className="row searchbar">
                                    <div className="col-xs-12 col-md-8">
                                        <form className="searchbar--form" onSubmit={handleSubmit}>
                                            <button value="\e900" aria-label="Rechercher" />
                                            <input type="text" name="query" placeholder="Entrez un numéro de série pour afficher la liste" ref={searchQuery} />
                                        </form>
                                    </div>
                                    <div className="col-xs-12 col-md-4">
                                        <Link to="/product/add" className="btn btn--green btn--full-width">Ajouter un produit</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <Cards list={deviceList} dataLength={totalItems} hasMore={true} fetchCallback={fetchData} />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </section>
        </ProtectedRoute>
    );
}

export default Products;