import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SIGN_OUT, useAuth } from '../context/authContext';
import { logout } from '../tools/auth';
import Logo from '../assets/images/logo/logo-neos-navbar.png';
import LogoX2 from '../assets/images/logo/logo-neos-navbar@2x.png';
import IconLogout from '../assets/images/icons/icon-logout.png';
import IconLogoutX2 from '../assets/images/icons/icon-logout@2x.png';

const Navbar = () => {

    const { authState, authDispatcher } = useAuth();
    const [navbarVisible, setNavbarVisible] = useState(false);

    const closeNav = () => {
        if(navbarVisible === true) {
            setNavbarVisible(false);
        }
    }

    return (
        <nav className="navbar">
            <div className="container">
                <div className="navbar--start">
                    <Link to="/" className="navbar--brand" onClick={closeNav}>
                        <img src={Logo} alt="Neos Health" srcSet={`${Logo} 1x, ${LogoX2} 2x`} />
                    </Link>
                    <button className={`navbar--button navbar--hamburger ${ navbarVisible === true ? 'navbar--hamburger-cross' : '' }`} onClick={() => {
                        if(navbarVisible === true) {
                            //Close navbar
                            setNavbarVisible(false);
                        } else {
                            //Open navbar
                            setNavbarVisible(true);
                        }
                    }}>
                        <div className="navbar--hamburger-line"></div>
                        <div className="navbar--hamburger-line"></div>
                        <div className="navbar--hamburger-line"></div>
                    </button>
                </div>
                <div className={`navbar--end ${navbarVisible === true ? 'navbar--open' : '' }`}>
                    <ul className="navbar--items">
                        <li className="navbar--item">
                            <Link to="/products" onClick={closeNav}>Produits</Link>
                        </li>
                        <li className="navbar--item">
                            <Link to="/savs" onClick={closeNav}>SAV</Link>
                        </li>
                        <li className="navbar--item">
                            <Link to="/users" onClick={closeNav}>Utilisateurs</Link>
                        </li>
                    </ul>
                    <div className="navbar--tools">
                        <p className="navbar--user">
                            { authState.user ? `${authState.user.surname} ${authState.user.name}` : '' }
                        </p>
                        <button className="navbar--button navbar--button-logout" onClick={() => {
                            closeNav();
                            logout(authState.userToken);
                            authDispatcher({ type: SIGN_OUT });
                        }}>
                            <img src={IconLogout} alt="Logout" srcSet={`${IconLogout} 1x,${IconLogoutX2} 2x`} />
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;