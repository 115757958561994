import axios from "axios";
import { BAD_VALUE, MISSING_VALUES, NOT_FOUND, OK } from "./content";
import { DELETE_USER, GET_ALL_USERS, GET_ROLES, GET_USER, LOGIN_URL, LOGOUT, REGISTER_USER, UPDATE_USER } from "./ServerEndpoint";

export const ERROR_EMPTY = 'EMPTY_VALUES';
export const ERROR_WRONG_LOGIN = 'WRONG_LOGIN';
export const BARER_TOKEN = "BARER_TOKEN";
export const BAD_LOGIN = 'BAD_LOGIN';
export const ERROR = 'ERROR';

export const login = async (username = '', password = '') => {
    if(username === '' && password === ''){
        return ERROR_EMPTY;
    }

    //Prepare the request
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', process.env.REACT_APP_CLIENT);
    formData.append('client_secret', process.env.REACT_APP_SECRET);
    formData.append('username', username);
    formData.append('password', password);

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
    };

    //Send login request
    try {
        const response = await axios.post(LOGIN_URL,formData,{ headers });
        localStorage.setItem(BARER_TOKEN, response.data.access_token);
        return response.data;
    } catch (error) {
        console.log(error.response);
        if(error.response.status === 400) {
            return BAD_LOGIN;
        } else {
            return ERROR;
        }
    }
}

export const get_current_user = async (token) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
    
    try {
        const response = await axios.get(GET_USER, { headers });
        return response.data;
    } catch (error) {
        return ERROR;
    }
}

export const logout = (token = '') => {
    logout_user(token).then(() => {
        localStorage.removeItem(BARER_TOKEN);
    });
}

export const refreshToken = () => {
    const refresh = localStorage.getItem(BARER_TOKEN);

    if(refresh === null) {
        return null;
    } else {
        //Send refresh request
        return refresh;
    }
}

export const get_all_user = async (token = '', page = 1) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.get(`${GET_ALL_USERS}?page=${page}`, { headers });
        return response.data;
    } catch (error) {
        return ERROR;
    }
}

export const get_user = async (token = '', id = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.get(`${GET_USER}/${id}`, { headers });
        return response.data;
    } catch (error) {
        if(error.response.status === 404) {
            return NOT_FOUND;
        } else {
            return ERROR;
        }
    }
}

export const get_roles = async (token = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.get(GET_ROLES, { headers });
        return response.data;
    } catch (error) {
        return ERROR;
    }
}

export const add_user = async (token = '', name = '', surname = '', email = '', password = '', role = '') => {
    if(token === '' || name === '' || surname === '' || email === '' || password === '' || role === '') {
        return MISSING_VALUES;
    }

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('surname', surname);
    formData.append('email', email);
    formData.append('password', password);
    formData.append('role_id', role);

    try {
        const response = await axios.post(REGISTER_USER, formData, { headers });
        return response.data;
    } catch (error) {
        switch (error.response.status) {
            case 422:
                return BAD_VALUE;
            default :
                return ERROR;
        }
    }
}

export const update_user = async (token = '', id = '',name = '', surname = '', email = '', old_email = '', password = '', role = '') => {
    if(token === '' || id === '' || surname === '') {
        return MISSING_VALUES;
    }

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('surname', surname);
    if(email !== old_email) {
        formData.append('email', email);
    }
    if(password !== '') {
        formData.append('password', password)
    }
    if(role !== '') {
        formData.append('role_id', role);
    }
    

    try {
        const response = await axios.post(`${UPDATE_USER}/${id}`, formData, { headers });
        return response.data;
    } catch (error) {
        switch (error.response.status) {
            case 422:
                return BAD_VALUE;
            default :
                return ERROR;
        }
    }
}

export const delete_user = async (token = '', id = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        await axios.delete(`${DELETE_USER}/${id}`, { headers });
        return OK;
    } catch (error) {
        return ERROR;
    }
}

export const logout_user = async (token = '') => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }

    try {
        await axios.delete(LOGOUT, { headers });
        return OK;
    } catch (error) {
        return ERROR;
    }
}

