import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../components/Alert';
import Cards from '../components/Cards';
import { useAuth } from '../context/authContext';
import { ERROR, get_all_user } from '../tools/auth';
import ProtectedRoute from '../tools/ProtectedRoute';

const Users = () => {

    const searchQuery = useRef();

    const [userList, setUserList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { authState } = useAuth();

    const pushToUserList = (data = [], clear = false) => {
        const newData = [];
        console.log(newData);

        data.forEach(element => {
            newData.push({
                title: `${element.surname} ${element.name}`,
                subTitle: `${element.role.name}`,
                color: 'black',
                link: `/user/${element.id}`
            });
        });

        if (clear === true) {
            setUserList(newData);
        } else {
            setUserList([
                ...userList,
                ...newData
            ]);
        }
    }

    const getData = async (query = '', clear = false, currentPage = page) => {
        if (query === '') {
            //All
            const response = await get_all_user(authState.userToken, currentPage);
            if (response === ERROR) {
                setTotalItems(0);
                setUserList([]);
                setPage(1);
                setError("Une erreur est survenue");
            } else {
                setTotalItems(response.total);
                pushToUserList(response.data);
                setPage(response.currentPage + 1);
                setError(null);
            }
        } else {
            //Search
        }
    }

    const fetchData = () => {
        getData(searchQuery.current.value, false);
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setPage(1);
        getData(searchQuery.current.value, true, 1);
    }

    useEffect(() => {
        if (authState.userToken !== null) {
            getData().then(() => {
                setIsLoading(false);
            });
        }
    }, [authState.userToken]);

    return (
        <ProtectedRoute>
            <section className="page page--users">
                <div className="container">
                    {
                        isLoading ? (
                            <h2>Chargement ...</h2>
                        ) : error ? (
                            <Alert message={error} />
                        ) : (
                            <>
                                <div className="row searchbar">
                                    <div className="col-xs-12 col-md-8">
                                        <form className="searchbar--form" onSubmit={handleSubmit}>
                                            <button value="\e900" aria-label="Rechercher" />
                                            <input type="text" name="query" placeholder="Entrez un nom ou un prénom" ref={searchQuery} />
                                        </form>
                                    </div>
                                    {
                                        authState.user.role.can_edit_user ? (
                                            <div className="col-xs-12 col-md-4">
                                                <Link to="/user/add" className="btn btn--green btn-full-width">Ajouter un utilisateur</Link>
                                            </div>
                                        ) : (
                                            ''
                                        )
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-xs-12">
                                        <Cards list={userList} dataLength={totalItems} fetchCallback={fetchData} hasMore={true} />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </section>
        </ProtectedRoute>
    );
}

export default Users;