import { React, useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useParams } from 'react-router-dom';
import Alert from '../components/Alert';
import { useAuth } from '../context/authContext';
import { delete_user, ERROR, get_user } from '../tools/auth';
import { NOT_FOUND } from '../tools/content';
import ProtectedRoute from '../tools/ProtectedRoute';

const User = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [canDelete, setCanDelete] = useState(false);
    let params = useParams();
    const { authState } = useAuth();

    const deleteUser = () => {
        delete_user(authState.userToken, params.userId).then((response) => {
            switch (response) {
                case ERROR: {
                    confirmAlert({
                        title: 'Erreur',
                        message: 'Une erreur est survenue',
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => {}
                            }
                        ]
                    });
                    break;
                }
                default: {
                    confirmAlert({
                        title: 'Utilisateur supprimé',
                        message: 'L\'utilisateur à bien été supprimé.',
                        buttons: [
                            {
                                label: 'Ok',
                                onClick: () => {
                                    window.location.href = "/users";
                                }
                            }
                        ]
                    })
                }
            }
        });
    }

    useEffect(() => {
        if (authState.userToken && authState.user) {
            get_user(authState.userToken, params.userId).then(response => {
                if (response === NOT_FOUND) {
                    setError('Utilisateur introuvable');
                } else if (response === ERROR) {
                    setError('Une erreur est survenue');
                } else {
                    setUser(response);
                    if(authState.user.id !== params.userId && authState.user.role.can_edit_user) {
                        setCanDelete(true);
                    }
                }
                setIsLoading(false);
            });
        }
    }, [params, authState]);

    return (
        <ProtectedRoute>
            <section className="page page--user">
                <div className="container">
                    <div className="row">
                        {
                            isLoading ? (
                                <h2>Chargement ...</h2>
                            ) : error ? (
                                <Alert message={error} />
                            ) : (
                                <>
                                    <div className="col-xs-12 col-md-4">
                                        <h2 className="title title--large">{user.surname} {user.name}</h2>
                                        <p className="title title--medium">{user.role.name}</p>
                                        {
                                            authState.userToken === params.userId || authState.user && authState.user.role.can_edit_user ? (
                                                <Link to={`/user/edit/${params.userId}`} className="btn btn--black">Modifier</Link>
                                            ) : (
                                                ''
                                            )
                                        }
                                        {
                                            canDelete ? (
                                                <button className="btn btn--red" onClick={() => {
                                                    confirmAlert({
                                                        title: 'Confirmation',
                                                        message: 'Étes vous sur de vouloir supprimer cet utilisateur ? Cette action est irréversible.',
                                                        buttons: [
                                                            {
                                                                label: 'Oui',
                                                                onClick: () => deleteUser()
                                                            },
                                                            {
                                                                label: 'Non',
                                                                onClick: () => {}
                                                            }
                                                        ]
                                                    })
                                                }}>Supprimer l'utilisateur</button>
                                            ) : (
                                                ''
                                            )
                                        }
                                    </div>
                                    <div className="col-xs-12 col-md-1" />
                                    <div className="col-xs-12 col-md-7">
                                        <p className="title title--small">Historique utilisateur bientôt disponible</p>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </section>
        </ProtectedRoute>
    );
}

export default User;