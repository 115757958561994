import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';

const Cards = ({list=[], dataLength, fetchCallback, hasMore}) => {
    return (
        <InfiniteScroll 
            className="card-grid"
            dataLength={dataLength}
            next={fetchCallback}
            hasMore={hasMore}>
            {
                list.map((element, index) => {
                    if(element.link !== undefined && element.link !== null) {
                        return (
                            <Link key={index}  to={element.link} id={index} className={ `card card--${ element.color !== undefined && element.color !== null ? element.color : 'black' } card--link` }>
                                <p className="card-title">{element.title !== undefined ? element.title !== null ? element.title : 'null' : 'Undefined'}</p>
                                {
                                    element.subTitle !== undefined && element.subTitle !== null && (
                                        <p className="card-sub-title">{ element.subTitle }</p>
                                    )
                                }
                            </Link>
                        )
                    } else {
                        return (
                            <article key={index} id={index} className={ `card card--${ element.color !== undefined && element.color !== null ? element.color : 'black' }` }>
                                <p className="card-title">{element.title !== undefined ? element.title !== null ? element.title : 'null' : 'Undefined'}</p>
                                {
                                    element.subTitle !== undefined && element.subTitle !== null && (
                                        <p className="card-sub-title">{ element.subTitle }</p>
                                    )
                                }
                            </article>
                        )
                    }
                })
            }
        </InfiniteScroll>
    )
}

export default Cards;