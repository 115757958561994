import { ErrorMessage, Field, Form, Formik } from 'formik';
import { React, useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import Alert from '../components/Alert';
import { useAuth } from '../context/authContext';
import { ERROR } from '../tools/auth';
import { add_sav, BAD_VALUE, edit_sav, get_sav, MISSING_VALUES, NOT_FOUND, search_devices } from '../tools/content';
import ProtectedRoute from '../tools/ProtectedRoute';

const SavForm = () => {

    let params = useParams();
    const [initialValues, setInitialValues] = useState({
        clientName: '',
        clientSurname: '',
        productSerial: '',
        clientTracking: '',
        returnTracking: '',
        wooNumber: '',
        notes: ''
    });
    const [savValidation, setSavValidation] = useState(Yup.object().shape({
        clientName: Yup.string().required('Le nom du client est obligatoire'),
        clientSurname: Yup.string().required('Le prénom du client est obligatoire'),
        productSerial: Yup.string().required('Le numéro de série du module est obligatoire'),
        clientTracking: Yup.string().notRequired(),
        returnTracking: Yup.string().notRequired(),
        wooNumber: Yup.string().required('Le numéro de commande est obligatoire'),
        notes: Yup.string().notRequired()
    }));
    const [isLoading, setIsLoading] = useState(true);
    const [sucess, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [errorBanner, setErrorBanner] = useState(null);
    const { authState } = useAuth();

    const submit = (values) => {
        //Check if device Exist
        search_devices(authState.userToken, values.productSerial).then((devices) => {
            if(devices === ERROR) {
                setErrorBanner('Une erreur est survenue');
            } else {
                //Check if serial is exact
                if(devices.data[0] && devices.data[0].serial_number === values.productSerial.toUpperCase()) {
                    const deviceID = devices.data[0].id;

                    //Send the request
                    if(params.savId) {
                        //Update
                        edit_sav(authState.userToken, params.savId, values.clientName, values.clientSurname, deviceID, values.wooNumber, values.clientTracking, values.returnTracking, values.notes).then((response) => {
                            if(response === BAD_VALUE) {
                                setErrorBanner("L'apparail souhaité n'est pas enregistré.");
                            } else if (response === ERROR) {
                                setErrorBanner("Une erreur est survenue");
                            } else if (response === MISSING_VALUES) {
                                setErrorBanner("Veuillez remplir les champs obligatoire");
                            } else {
                                setSuccess(response.id);
                            }
                        });
                    } else {
                        //Create
                        add_sav(authState.userToken, values.clientName, values.clientSurname, deviceID, values.wooNumber, values.clientTracking, values.notes).then((response) => {
                            if(response === BAD_VALUE) {
                                setErrorBanner("L'apparail souhaité n'est pas enregistré.");
                            } else if (response === ERROR) {
                                setErrorBanner("Une erreur est survenue");
                            } else if (response === MISSING_VALUES) {
                                setErrorBanner("Veuillez remplir les champs obligatoire");
                            } else {
                                setSuccess(response.id);
                            }
                        });
                    }
                } else {
                    setErrorBanner("L'apparail souhaité n'est pas enregistré.");
                }
            }
        })
    }

    useEffect(() => {
        if (authState.userToken !== null) {
            if (params.savId) {
                //In edit mode
                get_sav(authState.userToken, params.savId).then((response) => {
                    if(response === NOT_FOUND) {
                        setError('SAV introuvable');
                        setIsLoading(false);
                    } else {
                        setInitialValues({
                            clientName: response.sav.client_name,
                            clientSurname: response.sav.client_surname,
                            productSerial: response.sav.device.serial_number,
                            clientTracking: response.sav.client_tracking ? response.sav.client_tracking : '',
                            returnTracking: response.sav.return_tracking ? response.sav.return_tracking : '',
                            wooNumber: response.sav.woo_number,
                            notes: response.sav.notes ? response.sav.notes : ''
                        });
                        setIsLoading(false);
                    }
                });
            } else {
                //In creation mode
                setIsLoading(false);
            }
        }
    }, [params, authState.userToken]);

    return (
        <ProtectedRoute>
            <section className="page page--sav-form">
                <div className="container">
                    <div className="row">
                        {
                            isLoading ? (
                                <h2>Chargement ...</h2>
                            ) : sucess ? (
                                <Redirect to={`/sav/${sucess}`} />
                            ) : error ? (
                                <Alert message={error} />
                            ) : (
                                <>
                                    <div className="col-xs-12 col-md-4">
                                        <h2 className="title title--large">{params.savId ? 'Modification' : 'Enregistrement'} d'un SAV</h2>
                                        <p className="title title--meduim">{params.savId ? 'Modifiez' : 'Enregistrez'} un SAV dans le système pour garentir un suivi précis</p>
                                    </div>
                                    <div className="col-xs-12 col-md-1" />
                                    <div className="col-xs-12 col-md-7">
                                        {
                                            errorBanner && (
                                                <Alert message={errorBanner} />
                                            )
                                        }
                                        <Formik
                                            initialValues={initialValues}
                                            validationSchema={savValidation}
                                            onSubmit={submit}>
                                            <Form className="form">
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-md-6">
                                                        <label htmlFor="clientName">Nom</label>
                                                        <Field name="clientName" type="text" />
                                                        <ErrorMessage name="clientName" component="label" className="form--error" />
                                                    </div>
                                                    <div className="col-xs-12 col-md-6">
                                                        <label htmlFor="clientSurname">Prénom</label>
                                                        <Field name="clientSurname" type="text" />
                                                        <ErrorMessage name="clientSurname" component="label" className="form--error" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12">
                                                        <label htmlFor="productSerial">N° de série</label>
                                                        <Field name="productSerial" type="text" />
                                                        <ErrorMessage name="productSerial" component="label" className="form--error" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12">
                                                        <label htmlFor="wooNumber">Numéro de commande WooCommerce</label>
                                                        <Field name="wooNumber" type="text" />
                                                        <ErrorMessage name="wooNumber" component="label" className="form--error" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12">
                                                        <label htmlFor="clientTracking">N° de tracking client</label>
                                                        <Field name="clientTracking" type="text" />
                                                    </div>
                                                </div>
                                                {
                                                    params.savId && (
                                                        <div className="form-row">
                                                            <div className="col-xs-12">
                                                                <label htmlFor="returnTracking">N° de tracking retour</label>
                                                                <Field name="returnTracking" type="text" />
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                                <div className="form-row">
                                                    <div className="col-xs-12">
                                                        <label htmlFor="notes">Notes</label>
                                                        <Field name="notes" as="textarea" />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12">
                                                        <button type="submit" className="btn btn--green btn--full-width">{params.savId ? 'Modifiez' : 'Enregistrez'} le SAV</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Formik>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </section>
        </ProtectedRoute>
    );
}

export default SavForm;