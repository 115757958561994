import React from 'react';
import './scss/app.scss';
import './App.css';
import { AuthProvider } from './context/authContext';
import Manager from './tools/Manager';


function App() {
  return (
    <AuthProvider>
      <Manager />
    </AuthProvider>
  );
}

export default App;
