import { React, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Alert from '../components/Alert';
import { useAuth } from '../context/authContext';
import { ERROR } from '../tools/auth';
import { BAD_VALUE, get_sav, NOT_FOUND, sav_arrived, sav_working } from '../tools/content';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Sav = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [sav, setSav] = useState(null);
    const [error, setError] = useState(null);
    let params = useParams();
    const { authState } = useAuth();

    const load_product = async () => {
        const response = await get_sav(authState.userToken, params.savId);
        if (response === NOT_FOUND) {
            setError('SAV introuvable');
        } else if (response === ERROR) {
            setError('Une erreur est survenue');
        } else {
            setSav(response);
        }
    }

    useEffect(() => {
        if (authState.userToken) {
            load_product().then(() => {
                setIsLoading(false);
            });
        }
    }, [params, authState.userToken]);

    return (
        <section className="page page--sav">
            <div className="container">
                <div className="row">
                    {
                        isLoading ? (
                            <h2>Chargement ...</h2>
                        ) : error ? (
                            <Alert message={error} />
                        ) : sav && (
                            <>
                                <div className="col-xs-12 col-md-4">
                                    <h2 className="title title--large">SAV {sav.sav.number}</h2>
                                    <p className="title title--medium">
                                        {sav.sav.client_surname} {sav.sav.client_name}<br />
                                        {sav.sav.device.product.brand.name} {sav.sav.device.product.name}<br />
                                        {sav.sav.device.serial_number}
                                    </p>
                                    <p className="text">Enregistré par :<br />{sav.sav.user.surname} {sav.sav.user.name}</p>
                                    <p className="text">Date de mise en service :<br />{new Date(sav.sav.device.boot_date).toLocaleDateString(undefined)}</p>
                                    <p className="text">N° Woo :<br />{sav.sav.woo_number}</p>
                                    <p className="text">Commande N° :<br />{sav.sav.device.order.ecom_number}</p>
                                    {
                                        sav.sav.client_tracking && (
                                            <p className="text">Tracking Client :<br />{sav.sav.client_tracking}</p>
                                        )
                                    }
                                    {
                                        sav.sav.return_tracking && (
                                            <p className="text">Tracking retour :<br />{sav.sav.return_tracking}</p>
                                        )
                                    }

                                    <p className="text">Status de la garentie :<br />{sav.warranty ? 'Valide' : 'Expirée'}</p>
                                    <Link to={`/sav/edit/${params.savId}`} className="btn btn--black">Modifier</Link>
                                    <Link to={`/product/${sav.sav.device_id}`} className="btn btn--black">Voir produit</Link>
                                    {
                                        sav.sav.client_tracking !== null && sav.sav.arrival_date === null && sav.sav.working_date === null && sav.sav.return_tracking === null && (
                                            <button className="btn btn--green" onClick={() => {
                                                confirmAlert({
                                                    title: 'Confirmation',
                                                    message: 'Étes vous sur que ce SAV est arrivé ? Cette action est irréversible.',
                                                    buttons: [
                                                        {
                                                            label: 'Oui',
                                                            onClick: () => sav_arrived(authState.userToken, params.savId).then((response) => {
                                                                if (response === BAD_VALUE) {
                                                                    confirmAlert({
                                                                        title: 'Erreur',
                                                                        message: 'Il semblerait que ce SAV est déjà arrivé. Veuillez recharger la page',
                                                                        buttons: [
                                                                            {
                                                                                label: 'Ok',
                                                                                onClick: () => {}
                                                                            }
                                                                        ]
                                                                    });
                                                                } else if (response === ERROR) {
                                                                    confirmAlert({
                                                                        title: 'Erreur',
                                                                        message: 'Une erreur est survenue',
                                                                        buttons: [
                                                                            {
                                                                                label: 'Ok',
                                                                                onClick: () => {}
                                                                            }
                                                                        ]
                                                                    });
                                                                } else {
                                                                    load_product();
                                                                }
                                                            })
                                                        },
                                                        {
                                                            label: 'Non',
                                                            onClick: () => { }
                                                        }
                                                    ]
                                                })
                                            }}>Enregistrer l'arrivée du SAV</button>
                                        )
                                    }
                                    {
                                        sav.sav.client_tracking !== null && sav.sav.arrival_date !== null && sav.sav.working_date === null && sav.sav.return_tracking === null && (
                                            <button className="btn btn--green" onClick={() => {
                                                confirmAlert({
                                                    title: 'Confirmation',
                                                    message: 'Étes vous sur que vous prenez en charge ce SAV ? Cette action est irréversible.',
                                                    buttons: [
                                                        {
                                                            label: 'Oui',
                                                            onClick: () => sav_working(authState.userToken, params.savId).then((response) => {
                                                                if (response === BAD_VALUE) {
                                                                    confirmAlert({
                                                                        title: 'Erreur',
                                                                        message: 'Il semblerait que ce SAV est déjà pris en charge ou qu\'il ne soit pas arrivé. Veuillez recharger la page',
                                                                        buttons: [
                                                                            {
                                                                                label: 'Ok',
                                                                                onClick: () => {}
                                                                            }
                                                                        ]
                                                                    });
                                                                } else if (response === ERROR) {
                                                                    confirmAlert({
                                                                        title: 'Erreur',
                                                                        message: 'Une erreur est survenue',
                                                                        buttons: [
                                                                            {
                                                                                label: 'Ok',
                                                                                onClick: () => {}
                                                                            }
                                                                        ]
                                                                    });
                                                                } else {
                                                                    load_product();
                                                                }
                                                            })
                                                        },
                                                        {
                                                            label: 'Non',
                                                            onClick: () => { }
                                                        }
                                                    ]
                                                })
                                            }}>Prise en charge du SAV</button>
                                        )
                                    }
                                </div>
                                <div className="col-xs-12 col-md-1" />
                                <div className="col-xs-12 col-md-7">
                                    <h2 className="title title--large">Historique</h2>
                                    <ul className="list">
                                        {
                                            sav.sav.return_tracking && (
                                                <li className="list-item">
                                                    <div className="list-item--body">
                                                        <div className="list-item--small-col">
                                                            <p className="list-item--date">{new Date(sav.sav.send_back_date).toLocaleDateString(undefined)}</p>
                                                        </div>
                                                        <div className="list-item--large-col">
                                                            <p className="list-item--data">Terminé ({sav.sav.return_tracking})</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }
                                        {
                                            sav.sav.working_date && (
                                                <li className="list-item">
                                                    <div className="list-item--body">
                                                        <div className="list-item--small-col">
                                                            <p className="list-item--date">{new Date(sav.sav.working_date).toLocaleDateString(undefined)}</p>
                                                        </div>
                                                        <div className="list-item--large-col">
                                                            <p className="list-item--data">Traitement</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }
                                        {
                                            sav.sav.arrival_date && (
                                                <li className="list-item">
                                                    <div className="list-item--body">
                                                        <div className="list-item--small-col">
                                                            <p className="list-item--date">{new Date(sav.sav.arrival_date).toLocaleDateString(undefined)}</p>
                                                        </div>
                                                        <div className="list-item--large-col">
                                                            <p className="list-item--data">Arrivé à Genève</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }
                                        {
                                            sav.sav.client_tracking && (
                                                <li className="list-item">
                                                    <div className="list-item--body">
                                                        <div className="list-item--small-col">
                                                            <p className="list-item--date">{new Date(sav.sav.client_send_date).toLocaleDateString(undefined)}</p>
                                                        </div>
                                                        <div className="list-item--large-col">
                                                            <p className="list-item--data">Tracking client ({sav.sav.client_tracking})</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        }
                                        <li className="list-item">
                                            <div className="list-item--body">
                                                <div className="list-item--small-col">
                                                    <p className="list-item--date">{new Date(sav.sav.created_at).toLocaleDateString(undefined)}</p>
                                                </div>
                                                <div className="list-item--large-col">
                                                    <p className="list-item--data">Enregistrement dans le système</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <h3 className="title title--small">Notes :</h3>
                                    <p className="text text--line-breaker">{sav.sav.notes}</p>
                                </div>
                            </>
                        )
                    }
                </div>
            </div>
        </section>
    );
}

export default Sav;