import React, { useContext, useReducer } from 'react';

const AuthContext = React.createContext();
export const RESTORE_TOKEN = 'RESTORE_TOKEN';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

const authReducer = (state, action) => {
    switch (action.type) {
        case RESTORE_TOKEN: {
            return {
                ...state,
                userToken: action.token,
                user: action.user,
                isLoading: false,
            };
        }
        case SIGN_IN: {
            return {
                ...state,
                userToken: action.token,
                user: action.user,
                isSignout: false
            };
        }
        case SIGN_OUT: {
            return {
                ...state,
                userToken: null,
                user: null,
                isSignout: true
            }
        }
        default: {
            throw new Error('Non valid action');
        }
    }
};

export const AuthProvider = ({ children }) => {
    const [authState, authDispatcher] = useReducer(authReducer, {
        isLoading: true,
        isSignout: true,
        user: null,
        userToken: null
    });

    const value = { authState, authDispatcher };

    return (<AuthContext.Provider value={value}>{children}</AuthContext.Provider>)
}

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('Unable to find context');
    }
    return context;
}