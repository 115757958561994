//Auth
export const LOGIN_URL = process.env.REACT_APP_BACK + '/oauth/token';
export const GET_USER = process.env.REACT_APP_BACK + '/api/user';
export const GET_ALL_USERS = process.env.REACT_APP_BACK + '/api/user/all';
export const UPDATE_USER = process.env.REACT_APP_BACK + '/api/user/update';
export const REGISTER_USER = process.env.REACT_APP_BACK + '/api/user/create';
export const GET_ROLES = process.env.REACT_APP_BACK + '/api/user/roles';
export const DELETE_USER = process.env.REACT_APP_BACK + '/api/user/delete';
export const LOGOUT = process.env.REACT_APP_BACK + '/api/user/logout';

//Devices
export const GET_ALL_DEVICES = process.env.REACT_APP_BACK + '/api/device';
export const GET_DEVICE = process.env.REACT_APP_BACK + '/api/device';
export const SEARCH_DEVICE = process.env.REACT_APP_BACK + '/api/device/search';
export const ADD_DEVICE = process.env.REACT_APP_BACK + '/api/device/create';
export const UPDATE_DEVICE = process.env.REACT_APP_BACK + '/api/device/update';

//SAVS
export const GET_ALL_SAVS = process.env.REACT_APP_BACK + '/api/sav';
export const GET_SAV = process.env.REACT_APP_BACK + '/api/sav';
export const SEARCH_SAV = process.env.REACT_APP_BACK + '/api/sav/search';
export const ADD_SAV = process.env.REACT_APP_BACK + '/api/sav/create';
export const UPDATE_SAV = process.env.REACT_APP_BACK + '/api/sav/update';
export const ARRIVED_SAV = process.env.REACT_APP_BACK + '/api/sav/set-arrived';
export const WORKING_SAV = process.env.REACT_APP_BACK + '/api/sav/set-working';

//PRODUCTS
export const GET_ALL_PRODUCTS = process.env.REACT_APP_BACK + '/api/product/all';

//DASHBAORD
export const GET_DASHBAORD = process.env.REACT_APP_BACK + '/api/dashboard';