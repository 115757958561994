import React, { useEffect, useState } from 'react';
import Alert from '../components/Alert';
import Cards from '../components/Cards';
import { useAuth } from '../context/authContext';
import { ERROR } from '../tools/auth';
import { get_dashboard } from '../tools/content';
import ProtectedRoute from '../tools/ProtectedRoute';

const Home = () => {
    const [dashbaordList, setDashboardList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { authState } = useAuth();

    useEffect(() => {
        if (authState.userToken) {
            get_dashboard(authState.userToken).then((response) => {
                setLoading(false);
                if (response === ERROR) {
                    setError('Une erreur est survenue');
                } else {
                    setDashboardList([
                        {
                            title: response.sav_registred,
                            subTitle: 'SAV enregistré',
                            color: 'black',
                            link: null
                        },
                        {
                            title: response.sav_transit,
                            subTitle: 'SAV en transport',
                            color: 'black',
                            link: null
                        },
                        {
                            title: response.sav_waiting,
                            subTitle: 'SAV en attente',
                            color: 'black',
                            link: null
                        },
                        {
                            title: response.sav_in_progrss,
                            subTitle: 'SAV en traitement',
                            color: 'black',
                            link: null
                        },
                        {
                            title: response.sav_done,
                            subTitle: 'SAV traité',
                            color: 'black',
                            link: null
                        },
                        {
                            title: response.sav_total,
                            subTitle: 'SAV',
                            color: 'black',
                            link: null
                        },
                        {
                            title: response.device_total,
                            subTitle: 'Appareils enregistré',
                            color: 'black',
                            link: null
                        }
                    ])
                }
            });
        }
    }, [authState]);

    return (
        <ProtectedRoute>
            <section className="page page--home">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            {
                                loading ? (
                                    <h2>Chargement</h2>
                                ) : error ? (
                                    <Alert message={error} />
                                ) : (
                                    <Cards list={dashbaordList} dataLength={7} hasMore={false} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </ProtectedRoute>
    );
}

export default Home;