import React from "react";
import { Redirect } from "react-router";
import { useAuth } from "../context/authContext";

const LoggedOutRoute = ({ children }) => {
    const { authState } = useAuth();

    return (
        <>
            {
                authState.isLoading === false && authState.userToken !== null ? (
                    <Redirect to={{ pathname:"/" }} />
                ) : (
                    <>
                        { children }
                    </>
                )
            }
        </>
    )
};

export default LoggedOutRoute;