import { React, useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useParams, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import ProtectedRoute from '../tools/ProtectedRoute';
import { useAuth } from '../context/authContext';
import { add_device, BAD_VALUE, get_all_products, get_single_device, NOT_FOUND, update_device } from '../tools/content';
import Alert from '../components/Alert';
import { ERROR } from '../tools/auth';

const ProductForm = () => {
    let params = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [currentSerial, setCurrentSerial] = useState(null);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [errorBanner, setErrorBanner] = useState(null);
    const [success, setSuccess] = useState(null);
    const [initialValues, setInitialValues] = useState({
        model: "",
        serialNumber: "",
        bootDate: "",
        orderNumber: "",
        warranty: ""
    })
    const { authState } = useAuth();

    const productValidation = Yup.object().shape({
        model: Yup.string().required("Veuillez sélectionner une option"),
        serialNumber: Yup.string().required("Veuillez indiquer le numéro de série"),
        bootDate: Yup.date().required('Veuillez indiquez la date de mise en service'),
        orderNumber: Yup.string().required('Veuillez indiquer un numéro de commande'),
        warranty: Yup.string().required('Veuillez sélectioner une option')
    });

    const page_boot = async () => {
        const products_results = await get_all_products(authState.userToken);
        if (products_results !== ERROR) {
            setProducts(products_results);
            if (params.productId) {
                //In edit mode
                const device = await get_single_device(authState.userToken, params.productId);
                if (device === NOT_FOUND) {
                    //Device not found
                    setError('Aucun produit disponible');
                } else if (device === ERROR) {
                    //Server error
                    setError('Une erreur est survenue');
                } else {
                    //OK
                    setInitialValues({
                        model: device.device.product_id,
                        serialNumber: device.device.serial_number,
                        bootDate: device.device.boot_date,
                        orderNumber: device.device.order.ecom_number,
                        warranty: device.device.warranty
                    });
                    setCurrentSerial(device.device.serial_number)
                }
            }
        } else {
            //There is an error
            setError('Une erreur est survenue');
        }
        setIsLoading(false);
    }

    const submit = (values) => {
        if(params.productId) {
            //Update
            update_device(authState.userToken, params.productId, values.orderNumber, values.serialNumber, currentSerial, values.bootDate, values.model, values.warranty).then((response) => {
                if(response === BAD_VALUE) {
                    setErrorBanner('Veuillez vérifier vos valeurs');
                } else if (response === ERROR) {
                    setErrorBanner('Une erreur est survenue');
                } else {
                    setSuccess(response.id);
                }
            });
        } else {
            //Create
            add_device(authState.userToken, values.orderNumber, values.serialNumber, values.bootDate, values.model, values.warranty).then((response) => {
                if(response === BAD_VALUE) {
                    setErrorBanner('Veuillez vérifier vos valeurs');
                } else if (response === ERROR) {
                    setErrorBanner('Une erreur est survenue');
                } else {
                    setSuccess(response.id);
                }
            });
        }
    }

    useEffect(() => {
        if (authState.userToken !== null) {
            page_boot();
        }
    }, [params, authState.userToken]);

    return (
        <ProtectedRoute>
            <section className="page page--sav-form">
                <div className="container">
                    {
                        isLoading ? (
                            <h2>Chargement ...</h2>
                        ) : error ? (
                            <Alert message={error} />
                        ) : success ? (
                            <Redirect to={`/product/${success}`} />
                        ) : (
                            <>
                                <div className="col-xs-12 col-md-4 ">
                                    <h2 className="title title--large">{params.productId ? 'Modification' : 'Enregistrement'} d'un produit</h2>
                                    <p className="title title--medium">{params.productId ? 'Modifiez' : 'Enregistrez'} un produit dans le système pour assurer son suivi dans le temps</p>
                                </div>
                                <div className="col-xs-12 col-md-1" />
                                <div className="col-xs-12 col-md-7">
                                    {
                                        errorBanner && (
                                            <Alert message={errorBanner} />
                                        )
                                    }
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={productValidation}
                                        onSubmit={submit}>
                                        <Form className="form">
                                            <div className="form-row">
                                                <div className="col-xs-12">
                                                    <label htmlFor="model">Marque</label>
                                                    <Field name="model" as="select">
                                                        <option value="">Sélectionnez un model</option>
                                                        {
                                                            products.map((product) => (
                                                                <option key={product.id} value={product.id}>{product.name}</option>
                                                            ))
                                                        }
                                                    </Field>
                                                    <ErrorMessage name="model" component="label" className="form--error" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-xs-12">
                                                    <label htmlFor="serialNumber">N° de série</label>
                                                    <Field type="text" name="serialNumber" />
                                                    <ErrorMessage name="serialNumber" component="label" className="form--error" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-xs-12">
                                                    <label htmlFor="bootDate">Date de mise en service</label>
                                                    <Field type="date" name="bootDate" />
                                                    <ErrorMessage name="bootDate" component="label" className="form--error" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-xs-12">
                                                    <label htmlFor="bootDate">N° de commande</label>
                                                    <Field type="text" name="orderNumber" />
                                                    <ErrorMessage name="orderNumber" component="label" className="form--error" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-xs-12">
                                                    <label htmlFor="warranty">Extension de garantie</label>
                                                    <Field name="warranty" as="select">
                                                        <option value="">Sélectionnez une option</option>
                                                        <option value="NONE">Aucune</option>
                                                        <option value="6MONTH">6 mois</option>
                                                        <option value="12MONTH">12 mois</option>
                                                    </Field>
                                                    <ErrorMessage name="warranty" component="label" className="form--error" />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col-xs-12">
                                                    <button type="submit" className="btn btn--green btn--full-width">{params.productId ? 'Modifiez' : 'Enregistrez'} un produit</button>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            </>
                        )
                    }
                </div>
            </section>
        </ProtectedRoute>
    )
}

export default ProductForm;