import React from 'react';

const Alert = ({message=''}) => {
    return (
        <div className="alert alert--red">
            <h2>Oups</h2>
            <p>{ message }</p>
        </div>
    );
}

export default Alert;