import React, { useRef, useState, useEffect } from 'react';
import ProtectedRoute from '../tools/ProtectedRoute';
import Cards from '../components/Cards';
import Alert from '../components/Alert';
import { Link } from 'react-router-dom';
import { BAD_VALUE, get_savs, search_sav } from '../tools/content';
import { useAuth } from '../context/authContext';
import { ERROR } from '../tools/auth';

const Savs = () => {

    const searchQuery = useRef();
    const [currentFilter, setCurrentFilter] = useState('all');
    const [savList, setSavList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { authState } = useAuth();

    const pushToSavList = (data = [], clear = false) => {
        const newData = [];

        data.forEach(element => {
            let color = 'black';
            let subTitle = 'Enregitré';
            if (element.client_tracking !== null && element.arrival_date !== null && element.working_date !== null && element.return_tracking !== null) {
                //Terminated
                color = 'green';
                subTitle = 'Terminé';
            } else if (element.client_tracking !== null && element.arrival_date !== null && element.working_date !== null && element.return_tracking === null) {
                //Working
                color = 'blue';
                subTitle = 'En traitement';
            } else if (element.client_tracking !== null && element.arrival_date !== null && element.working_date === null && element.return_tracking === null) {
                //Waiting
                color = 'orange';
                subTitle = 'En attente';
            } else if (element.client_tracking !== null && element.arrival_date === null && element.working_date === null && element.return_tracking === null) {
                //Transit
                color = 'gray';
                subTitle = 'En transport';
            } else {
                //Registred
                color = 'black';
                subTitle = 'Enregistré';
            }

            newData.push({
                title: `SAV ${element.number}`,
                subTitle: subTitle,
                color: color,
                link: `sav/${element.id}`
            });
        });

        if (clear) {
            setSavList(newData);
        } else {
            setSavList([
                ...savList,
                ...newData
            ]);
        }
    }

    const getData = async (type = 'all', query = '', clear = false, currentPage = page) => {
        if (query === '') {
            //Filter
            const response = await get_savs(authState.userToken, currentPage, type);
            if (response === BAD_VALUE) {
                setTotalItems(0);
                setSavList([]);
                setPage(1);
                setError('Veuillez vérifier vos valeurs');
            } else if (response === ERROR) {
                setTotalItems(0);
                setSavList([]);
                setPage(1);
                setError('Une erreur est survenue');
            } else {
                setTotalItems(response.total);
                pushToSavList(response.data, clear);
                setPage(response.current_page + 1);
                setError(null);
            }
        } else {
            //Search
            const response = await search_sav(authState.userToken, currentPage, query);
            if (response === ERROR) {
                setTotalItems(0);
                setSavList([]);
                setPage(1);
                setError('Une erreur est survenue');
            } else {
                setTotalItems(response.total);
                pushToSavList(response.data, clear);
                setPage(response.current_page + 1);
                setError(null);
            }
        }
    }

    const fetchData = () => {
        getData(currentFilter, searchQuery.current.value, false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setCurrentFilter('all');
        setPage(1);
        getData('all', searchQuery.current.value, true, 1);
    }

    const handleFilter = (filter) => {
        setCurrentFilter(filter);
        searchQuery.current.value = '';
        setPage(1);
        getData(filter, '', true, 1);
    }

    useEffect(() => {
        if (authState.userToken !== null) {
            getData().then(() => {
                setIsLoading(false);
            });
        }
    }, [authState.userToken]);

    return (
        <ProtectedRoute>
            <section className="page page--savs">
                <div className="container">
                    <div className="row searchbar">
                        <div className="col-xs-12 col-md-8">
                            <form className="searchbar--form" onSubmit={handleSubmit}>
                                <button value="\e900" aria-label="Rechercher" />
                                <input type="text" name="query" placeholder="Entrez un numéro de SAV ou Woo ou nom" ref={searchQuery} />
                            </form>
                        </div>
                        <div className="col-xs-12 col-md-4">
                            <Link to="/sav/add" className="btn btn--green btn--full-width">Enregistrer un SAV</Link>
                        </div>
                    </div>
                    <div className="row filter">
                        <div className="col-xs-12">
                            <p className="filter--title">Filtre :</p>
                            <ul className="filter--list">
                                <li className={`filter--item${currentFilter === 'all' ? ' filter--item-selected' : ''}`} onClick={() => handleFilter('all')}>
                                    Tous
                                </li>
                                <li className={`filter--item${currentFilter === 'registred' ? ' filter--item-selected' : ''}`} onClick={() => handleFilter('registred')}>
                                    Enregistré
                                </li>
                                <li className={`filter--item${currentFilter === 'transit' ? ' filter--item-selected' : ''}`} onClick={() => handleFilter('transit')}>
                                    En transport
                                </li>
                                <li className={`filter--item${currentFilter === 'waiting' ? ' filter--item-selected' : ''}`} onClick={() => handleFilter('waiting')}>
                                    En attente
                                </li>
                                <li className={`filter--item${currentFilter === 'processing' ? ' filter--item-selected' : ''}`} onClick={() => handleFilter('processing')}>
                                    En traitement
                                </li>
                                <li className={`filter--item${currentFilter === 'terminated' ? ' filter--item-selected' : ''}`} onClick={() => handleFilter('terminated')}>
                                    Terminé
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            {
                                isLoading ? (
                                    <h2>Chargement ...</h2>
                                ) : error ? (
                                    <Alert message={error} />
                                ) : (
                                    <Cards
                                        list={savList}
                                        hasMore={true}
                                        dataLength={totalItems}
                                        fetchCallback={fetchData} />
                                )
                            }
                        </div>
                    </div>
                </div>
            </section>
        </ProtectedRoute>
    );
}

export default Savs;