import React from "react";

const NoPage = () => {
    return (
        <section className="page page--notFound">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12">
                        <h1 className="title title--large title--black">404</h1>
                        <p className="title title--medium title--black">Page not found</p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NoPage;